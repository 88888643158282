import { isUndefined } from "lodash";
import appConfig from "../../../appConfig";
import { TenantConfig } from "../../../platform/core/hooks/tenant-config";
import datasourceApiManager from "../../../platform/services/api/DatasourceApiService";
import { ApptuitDatasource } from "../../../platform/services/datasources/apptuit/ApptuitDatasource";
import { DruidIngestionConfig } from "../../traces/Analytics/types";
import { Role } from "../../../platform/core";
import { InceptionResponse } from "../../../platform/services/api/types";
import {
  AddtenantRequest,
  TenantCustomConfiguration,
  DruidIngestionConfigResponse,
  ShardsResponse,
  Tenant,
  UserHome
} from "./types";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}
interface ResponseMessage {
  message: string;
}
class TenantApiService {
  readonly NAMESPACE: string = "ui";
  readonly CONFIG_NAME: string = "tenant_config";

  private datasource: ApptuitDatasource;

  protected async init() {
    this.datasource = await datasourceApiManager.getDefault();
  }

  async getTenants(): Promise<Result<Tenant[]>> {
    const result: Result<Tenant[]> = {
      data: [],
      error: false,
      message: ""
    };
    const url = "/api/tenants";
    try {
      await this.init();
      const response = await this.datasource.get<Tenant[], unknown>(url, null, {
        params: { includeTenantConfig: true }
      });
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response?.data.message;
    }
    return result;
  }

  async getCurrentTenant(): Promise<Result<Tenant>> {
    const result: Result<Tenant> = {
      data: null,
      error: false,
      message: ""
    };
    const url = "/api/tenants/current";
    try {
      await this.init();
      const response = await this.datasource.get<Tenant, null>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response?.data.message;
    }
    return result;
  }

  async addTenant(data: AddtenantRequest): Promise<Result<ResponseMessage>> {
    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };
    const url = "/api/tenants";
    try {
      await this.init();
      const response = await this.datasource.post<ResponseMessage, AddtenantRequest>(url, data);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }
    return result;
  }

  async renameTenant(tenantId: string, newTenantName: string): Promise<Result<ResponseMessage>> {
    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };

    const url = `/api/tenants/${tenantId}/name/${newTenantName}`;

    try {
      await this.init();
      const response = await this.datasource.put<ResponseMessage, null>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }
    return result;
  }

  async getShards(): Promise<Result<ShardsResponse>> {
    const result: Result<ShardsResponse> = {
      data: {
        i_anomMetricsShard: [],
        i_metricsShard: [],
        i_tracesShard: []
      },
      error: false,
      message: ""
    };
    const url = "/api/tenants/shards";
    try {
      await this.init();
      const response = await this.datasource.get<ShardsResponse, null>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response?.data.message;
    }
    return result;
  }

  async getUserHome(): Promise<InceptionResponse<UserHome>> {
    const url = `/api/tenants/users/current-user/home`;
    await this.init();
    const response = await this.datasource.get<UserHome, null>(url);
    return response;
  }

  async updateTenantConfig(tenantConfig: TenantConfig): Promise<string> {
    const url = `/api/tenants/current/config/${this.NAMESPACE}/${this.CONFIG_NAME}`;
    await this.init();
    type UpdateResponse = {
      Status: string;
    };
    const response = await this.datasource.put<UpdateResponse, TenantConfig>(url, tenantConfig);
    return response.data.Status;
  }

  private async getCurrentTenantConfig(): Promise<TenantConfig> {
    const url = `/api/tenants/current/config/${this.NAMESPACE}/${this.CONFIG_NAME}`;
    await this.init();
    const response = await this.datasource.get<TenantConfig, null>(url);
    return response.data;
  }

  private getMockTenantConfig(): Promise<TenantConfig> {
    return Promise.resolve(mockTenantConfig as TenantConfig);
  }

  getTenantConfig(role: Role): Promise<TenantConfig> {
    if (role === Role.Guest || appConfig.anomShareId) {
      return this.getMockTenantConfig();
    } else {
      return this.getCurrentTenantConfig();
    }
  }

  async getCurrentTenantTimezone(): Promise<Result<string>> {
    const result: Result<string> = {
      data: "",
      error: false,
      message: ""
    };
    const url = `/api/tenants/current/config/defaultTimeZone`;

    try {
      await this.init();
      const response = await this.datasource.get<string, null>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response?.data.message;
    }
    return result;
  }

  async getDruidIngestionConfig(): Promise<DruidIngestionConfig> {
    const url = "/api/tenants/system/config/druid/ingestion";
    await this.init();
    const response = await this.datasource.get<DruidIngestionConfigResponse, null>(url);
    const data = response?.data;
    const config: DruidIngestionConfig = {
      trace: isUndefined(data.__trace) ? true : data.__trace,
      event: isUndefined(data.__event) ? true : data.__event,
      preview: isUndefined(data.__preview) ? true : data.__preview,
      sketch: isUndefined(data.__sketch) ? true : data.__sketch
    };
    return config;
  }

  async fetchAllConfigs(tenantId: string): Promise<Result<TenantCustomConfiguration[]>> {
    const result: Result<TenantCustomConfiguration[]> = {
      data: [],
      error: false,
      message: ""
    };

    const url = `/api/tenants/${tenantId}/configs`;

    try {
      await this.init();
      const response = await this.datasource.get<TenantCustomConfiguration[], null>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }
    return result;
  }

  private async createOrUpdateConfig(
    tenantId: string,
    config: TenantCustomConfiguration
  ): Promise<Result<ResponseMessage>> {
    const result: Result<ResponseMessage> = {
      data: { message: "" },
      error: false,
      message: ""
    };
    const url = config.namespace
      ? `/api/tenants/${tenantId}/config/${config.namespace}/${config.name}`
      : `/api/tenants/${tenantId}/config/${config.name}`;
    try {
      await this.init();
      const response = await this.datasource.put<ResponseMessage, TenantCustomConfiguration["config"]>(
        url,
        config.config
      );
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }

    return result;
  }

  async createConfig(tenantId: string, config: TenantCustomConfiguration): Promise<Result<ResponseMessage>> {
    return await this.createOrUpdateConfig(tenantId, config);
  }

  async updateConfig(tenantId: string, config: TenantCustomConfiguration): Promise<Result<ResponseMessage>> {
    return await this.createOrUpdateConfig(tenantId, config);
  }

  async deleteConfig(tenantId: string, config: TenantCustomConfiguration): Promise<Result<ResponseMessage>> {
    const result: Result<ResponseMessage> = {
      data: null,
      error: false,
      message: ""
    };

    const url = config.namespace
      ? `/api/tenants/${tenantId}/config/${config.namespace}/${config.name}`
      : `/api/tenants/${tenantId}/config/${config.name}`;
    try {
      this.init();
      const response = await this.datasource.delete(url);
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }
    return result;
  }
}
const tenantservice = new TenantApiService();
export { tenantservice };

const mockTenantConfig = {
  demoTenant: false,
  leftNavPreset: [] as any[],
  defaultDrilldownVersion: "v5",
  disableNewDashboardFilterPanel: false
};
