import React, { memo, useMemo } from "react";
import { CypressConstants } from "@bicycle/tests";
import { generateId } from "../../utils";
import { IncFaIcon } from "../Icons";
import { IncRadioButton } from "../antd-components";
import { RadioGroupProps } from "./types";

//when there is a type change in props, take care of sorting logic inside compareAndSortOptions function
const IncRadioGroup = memo<RadioGroupProps>(props => {
  const { className: pClassName = "", options, label, onChange, value: radioGroupValue, layout = "row" } = props;

  const uniqId = useMemo(() => generateId(), []);

  const optionsElement = useMemo(
    () =>
      (options || []).map(option => {
        const { label, value, icon, iconName } = option;

        const key = [uniqId, "radio-option", option.value].join("-");
        const iconExists = Boolean(icon) || Boolean(iconName);
        const labelJsx = iconExists ? (
          <div className="inc-flex-row inc-flex-center-vertical flex-gap-8">
            {Boolean(icon) && (
              <>
                {typeof icon === "string" && (
                  <div
                    className="inc-flex-row inc-flex-center-vertical"
                    dangerouslySetInnerHTML={{ __html: icon }}
                  />
                )}
                {typeof icon !== "string" && { icon }}
              </>
            )}
            {!icon && iconName && <IncFaIcon iconName={iconName} />}
            {label}
          </div>
        ) : (
          label
        );

        const onChangeInternal = () => onChange(value);

        return (
          <IncRadioButton
            checked={value === radioGroupValue}
            className="radio-group-container--option"
            data-cy={CypressConstants.application.attributes.radioButton}
            key={key}
            label={labelJsx}
            name={label}
            onChange={onChangeInternal}
            value={value}
          />
        );
      }),
    [options, uniqId, radioGroupValue, onChange]
  );

  return (
    <div
      className={`radio-group-container ${pClassName}`}
      data-layout={layout}
    >
      {Boolean(label) && <div className="inc-flex-row inc-flex-center-vertical">{label}</div>}
      <div className="radio-group-container--options">{optionsElement}</div>
    </div>
  );
});

export { IncRadioGroup };
